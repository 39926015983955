.jumbotron--single--vacature{
	height: 450px;
	margin-bottom: 60px;
	width: 100%;
	background-size:cover;
	background-repeat:no-repeat;
	position: relative;
		@media (max-width: 991.98px){
			height: 250px;
		}
	.vacature_title{
		max-width: 75%;
		text-align: left;
		color:#fff;
		font-size: 50px;
		line-height: 60px;
		position:absolute;
		bottom: 50px;
		left: 50px;
		@media (max-width: 991.98px) {
			text-shadow: 0px 1px #000;
			font-size: 20px;
			line-height: 25px;
			bottom: 15px;
			left: 15px;
		}
	}
}
.vacature--details{
	margin-bottom: 30px;
	font-size: 16px;
	color:#1E396B;

}
.icon__single__vacature{
	width: 22px;
	height: auto;
}

.border-left{
	border-left: 4px solid #1E396B!important;
}
.border-yellow{
	border-color:#FDD364!important;
}
span.button .sharebox{
	padding:10px 6px 1px!important;
	width: auto!important;
}

.sharebox__wrapper,
.sharebar{
	color: #1E396B;
 	.button{
		background-color:transparent!important;
		padding:0;
		margin: 0 0 30px 0;
		width: 100%;
		display: block!important;
	    text-align: left;
	    border-radius: 0;
	    color: #1E396B;
	    font-size: 16px;
	    line-height: 30px;
    	&:before,&:after{
    		display: none;
    	}
    	@media (max-width: 991.98px) {
    		margin: 0 0 10px 0;
			min-width: auto!important;
		}
	}
	> a{
		display: block;
		line-height: 30px;
		margin-bottom: 30px;
		width: 100%;
	}
	i{
		font-size: 20px;
    	width: 30px;
    	margin-right: 15px;
    	@media (max-width: 991.98px) {
    		margin-right: 10px;
    	}
	}
}
.sharebox__wrapper{
	margin-top: 50px;
	display: flex;
	justify-content: space-evenly;
	span.button,a{
		width: auto!important;
	}
	.addtoany_list{
		display: flex;
	}
}
span.button .sharebox{
	top: -49px!important;
}
.padding-left{
	padding-left: 15px!important;
}
.margin-top{
	margin-top: 30px;
}

.imgBox{
	position: relative;
	height: 0;
	width: 100%;
	padding-top: 110%;
	@media (max-width: 991.98px) {
		margin-bottom: 30px;
	}
	.imgBox--image{
		position: absolute;
    	max-width: 90%;
		&--left{
			left:0;
			bottom:0;
			z-index:2;
		}
		&--right{
			right:0;
			top:0;
			z-index:3;
		}
	}
}

