.home__slider {
	margin-bottom: 50px;
 .vimeo_box{
	position:relative;
	width:100%;
	height:0;
	padding-bottom:56.27198%;

	iframe{
		z-index: 998;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		border:none;
	}
}
.carousel-cell{
	width: 100%;
}
.carousel-cell{
	padding-bottom: 56.27198%;
	width: 100%;
	height: 0;
}
.carousel-cell img{
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
}

}
.main-carousel{
	@media (max-width: 991.98px) {
    	overflow: hidden;
	}
	.flickity-prev-next-button.previous{
		left: -60px!important;
	}
	.flickity-prev-next-button.next{
		right: -60px!important;
	}
	.flickity-button{
		background-color:transparent!important;
		color:#1D376C!important;
	}
}
.kernwaarde--list{
	list-style: none;
	li{
		margin-bottom: 30px;
	}
}
.kernwaarde--button{
	font-size: 22px;
	font-weight: 300;
	transition: 300ms;
	cursor: pointer;
	&.active{
		font-weight: bold;
		color:#FDD364;
	}
}
