.content--tertiary{
	background-color:#FDD364;
}

section{
	padding: 60px 0;
}
.single-vacatures .header + section{
	padding-top:82px!important;
}
.content h1, .content h2{
	font-size: 35px;
    margin: 0 0 40px;
    @media (max-width: 991.98px){
    	line-height: 32px;
    }
}
.content p{
	color: #1D376C!important;
}
.content--secondary--right{
	background-color: #E3E8EC;
}
h3,
h2,
h1,
h4,strong{
	color: #1E396B;
}
button.button,
.button--yellow,
a.button{
	&:after,
	&:before{
		display: none;
	}
	background-color:#1E396B;
	border-radius:25px;
	padding:14px 20px;
	min-width: 260px;
	font-weight: bold;
	color:#fff;
	&:hover{
		color:#1E396B;
		background-color: #E3E8EC;
	}
	&--plane{
		&:after{
			font-family: "Font Awesome 5 Pro";
		    font-weight: 300;
		    content: '\F1D8';
		    background-color: transparent;
		    display: inline-block;
		    position: relative;
		    margin-left: 12px;
		    color: #FDD364;
		}
	}
}
.button--yellow{
 	background-color:#FDD364!important;
}
.button--submit{
	margin: 0 auto;
	width: auto;
	display: table;
	color:#fff;
}


    .flex{
        display: flex;
        height: 100%;
        @media (max-width: 991.98px){
			width: 100%;
		}

    }
    .flex.jcenter{
        justify-content: center;
    }
    .flex.align-center{
        align-items: center;
    }
    .no-margin{
        margin: 0!important;
        padding: 0!important;
    }
