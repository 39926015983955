.referentie__slider{
	@media (max-width: 991.98px) {
		overflow: hidden;
	}
	.carousel-cell{
		width: 100%;
		.referentie__item{
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items:center;
			@media (max-width: 760px) {
				flex-direction: column;
			}
			.referentie__item__visual{
				height: 75px;
				width:75px;
				border:2px solid #1D376C;
				border-radius:50%;
			}
			.referentie__item__content{
				width: calc(100% - 125px);
					@media (max-width: 760px) {
				    	width: calc(100%);
    					text-align: center;
    					margin-top: 10px;
    				}

				.quote{
					color:#1D376C;
					font-style:italic;
					font-size: 18px;
					line-height: 32px;;
				}
				.name{
					margin-top: 15px;
					display: block;
					color:#1D376C;
					line-height: 28px;
					font-size: 18px;
					font-weight: bold;
				}
				.function{
					color:#1D376C;
					display: block;
					line-height: 28px;
					font-size: 18px;
					font-weight: 300;
				}
			}
		}
	}
}
