.content--question{
 h2{
 	@media (max-width: 991.98px){
    	margin-bottom: 30px;
    	text-align: center;
    }
 }
 .contact-person__social{
 	@media (max-width: 991.98px){
    	margin: 25px auto 0;
    }
 }
}

.m-center{
	@media (max-width: 991.98px){
		justify-content: center;
	}
}
.contact-person--name{
    font-weight: bold;
}
.contact-person--function{
    font-weight: 300;
}
.contact-person__social a i{
    margin-right: 30px;
}
.contact-person-image{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin-right: 30px;
}
