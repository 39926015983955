.lightbox__wapper{
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    .bg{
        background-color: rgba(0,0,0,0.8);
        position: fixed;
        top: 0;
        left:0;
        right: 0;
        bottom: 0;
        z-index: 99999;
    }
    .lightbox__header{
        text-align: center;
        position: relative;
        height: 60px;
        border-bottom: #EFEFEF;

        .close{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 25px;
            color: #1E396B;
        }

        .logo img{
            height: 40px;
            width: auto;
            margin: 10px auto;
        }

    }

    .lightbox__wrapper__inner{
        width: 90%;
        left: 5%;
        height: 90%;
        overflow: scroll;
        display: block;
        background-color: #fff;
        margin: 0 auto;
        top: 5%;
        position: absolute;
        z-index: 99999;
    }

    .image__wrapper{
        position: relative;

        .full__image{
            position: absolute;
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
        }
    }
    .wpcf7{
        margin: 20px 30px;

        h4{
            font-size: 28px;
            margin-bottom: 10px;
            margin-top: 0;
        }
        form{
            textarea,
            input[type='email'],
            input[type='date'],
            input[type='tel'],
            input[type='number'],
            input[type='text'],
            input[type='password']{
                border: 0;
                background-color: #f9f9f9;
                color: #333;
                width: 100%;
                border: none;
                border: none;
                height: 40px;
                padding: 0 15px 0 15px;
                font-weight: 300;
                font-size: 14px;
                -webkit-transition: 300ms;
                transition: 300ms;
                margin-bottom: 10px;
            }

            textarea{
                padding-top: 11px;
                line-height: 16px;;
                height: 90px!important;
            }

            label{
                font-size: 15px;
                font-weight: bold;
                display: block;
                color: #333;
                margin-bottom: 12px;
            }
            .wpcf7-list-item label{
                display: table;
                float: left;
                margin-right: 20px;
            }
            .filename-placeholder-text-motivatie,
            .filename-placeholder-text{
                display: block;
                text-align: left;
                font-weight: bold;
                margin-bottom: 10px;
            }
            br {
                display: block; /* makes it have a width */
                content: ""; /* clears default height */
                margin-top: 0; /* change this to whatever height you want it */
            }
            #file-upload{
                display: none;
            }

            .button--upload{
                color:#fff;
                display: table;
                width: auto;
            }
        }
    }
}

