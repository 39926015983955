ul.list{
	list-style: none;
	margin: 0 0 30px;
	padding:0;
	li{
		margin-bottom: 10px;
	    position: relative;
	    color: #1E396B !important;
	    font-size: 15px;
	    padding-left: 25px;
	    line-height: 25px;
		&:before{
			display: none;
			content: "";
		    height: 20px;
		    width: 20px;
		    background-color: transparent;
		    border: 2px solid #FDD364;
		    border-radius: 50%;
		    //display: block;
		    position: absolute;
		    left: 0;
		}
		i{
			position: absolute;
			top: 4px;
			left: 0;
			&.custom--icon{
				top: 0;
				background-size: contain;
			    height: 25px;
			    width: 20px;
			    background-repeat: no-repeat;
			    background-position: center;
			}
		}
	}
}
