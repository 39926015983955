.usp__list{
	list-style: none;
	margin: 0;
	padding:0;
	justify-content: space-between;
	align-items: flex-start;
	display: flex;
	@media (max-width: 760px) {
		flex-wrap: wrap;
	}
	li{
		display: block;
		text-align: center;
		@media (max-width: 760px) {
			width: 50%;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		img{
			height: 40px;
			width: auto;
		}
		h4{
			font-size: 18px;
			color:#1E396B;
			margin-bottom: 0;
			margin-top: 30px;
			font-weight: 400;
		}
	}
}
