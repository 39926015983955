@import 'plugins/flickity';

@import 'global/base';
@import 'global/checkbox';
@import 'global/list';


@import 'front-page/referentie-slider';
@import 'front-page/slider';
@import 'front-page/usps';



@import 'vacature/single-vacature';
@import 'vacature/single-vacature-card';
@import 'vacature/single-vacature-popup';
@import 'vacature/single-vacature-question';

