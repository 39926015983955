.cards{
	margin-bottom: 50px;
	display: block;
	.cards__visual{
		position: relative;
		height: 0;
		padding-top: 55%;
		.location{
			min-width: 150px;
			background-color: #FDD364;
			font-size: 12px;
			color:#1E396B;
			position: absolute;
			bottom: 14px;
			left: 14px;
			border-radius:5px;
			font-weight:bold;
			text-align: center;
			padding:5px;
		}
		img{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
	    	object-fit: cover;
		}
	}
	.cards__summary{
		h3{
			margin: 0;
			font-size: 17px;
			@media (max-width: 991.98px){
				margin: 10px 0 0;
			}
		}
		.cards__summary__specs{
			font-weight: 300;
		}
	}
}
